import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { FaBars, FaFolderOpen, FaSave } from "react-icons/fa";
import { MdImage } from "react-icons/md";
import { BiSolidPaperPlane, BiSolidFileJson  } from "react-icons/bi";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import ORCIDAuth from '../auth/ORCIDAuth';

function Menu({ onOptionClick }) {
  const burgerRef = useRef(null);
  const saveAsTriggerRef = useRef(null);
  const [saveAsTippyVisible, setSaveAsTippyVisible] = useState(false);

  const options = [
    { name: "Open", icon: <FaFolderOpen />, command: "open" },
    { name: "Publish", icon: <BiSolidPaperPlane />, command: "publish" },
    { name: "Save As", icon: <FaSave />, command: "saveAs" },
  ];

  const saveAsOptions = [
    { name: "Image", icon: <MdImage  />, command: "export" },
    { name: "Graph", icon: <BiSolidFileJson />, command: "save" },
  ];

  const createMainTippyContent = () => {
    return (
      <div>
        {options.map((opt, index) => (
          <div className="option-item" key={index} ref={opt.command === 'saveAs' ? saveAsTriggerRef : null} onClick={() => {
            if (opt.command === 'saveAs') {
              setSaveAsTippyVisible(true); // Show the Save As nested Tippy
            } else {
              onOptionClick(opt.command); // Handle normal option clicks
            }
          }}>
            {opt.icon}
            <span style={{ marginLeft: '5px' }}>{opt.name}</span>
          </div>
        ))}
        <hr style={{ backgroundColor: "lightgray" }}></hr>
        <ORCIDAuth />
      </div>
    );
  };

  const createSaveAsTippyContent = () => {
    return (
      <div>
        {saveAsOptions.map((opt, index) => (
          <div className="option-item" key={index} onClick={() => {
            onOptionClick(opt.command);
            setSaveAsTippyVisible(false);
            }}>
            <span style={{ fontSize: '18px' }}> {/* Increase size here */}
                {opt.icon}
            </span>
            <span style={{ marginLeft: '5px' }} >
                {opt.name}
            </span>
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (burgerRef.current) {
      // Create the content for the main Tippy
      const tippyContentDiv = document.createElement('div');
      tippyContentDiv.classList.add('design-pallete');
      document.body.appendChild(tippyContentDiv);
      ReactDOM.createRoot(tippyContentDiv).render(createMainTippyContent());

      // Initialize Tippy for the main menu
      tippy(burgerRef.current, {
        content: tippyContentDiv,
        allowHTML: true,
        interactive: true,
        arrow: false,
        trigger: 'click',
        placement: 'top',
        theme: 'bgpalette',
        zIndex: 5000,
      });
    }

    if (saveAsTippyVisible && saveAsTriggerRef.current) {
      const saveAsDiv = document.createElement('div');
      saveAsDiv.classList.add('design-pallete');
      document.body.appendChild(saveAsDiv);
      ReactDOM.createRoot(saveAsDiv).render(createSaveAsTippyContent());

      // Create the nested Tippy for "Save As"
      tippy(saveAsTriggerRef.current, {
        content: saveAsDiv,
        allowHTML: true,
        interactive: true,
        arrow: false,
        trigger: 'click',
        placement: 'right',
        theme: 'bgpalette',
        hideOnClick: 'toggle',
        zIndex: 5000,
        onHidden: () => setSaveAsTippyVisible(false),
      }).show();
    }
  }, [saveAsTippyVisible]);

  return (
    <div ref={burgerRef}>
      <FaBars style={{ fontSize: '18px' }} />
    </div>
  );
}

export default Menu;
