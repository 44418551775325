import React, { useEffect } from "react";
import './index.css';
import './App.css';
import Paper from './components/Paper';

function App() {
  useEffect(() => {

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
      <Paper />
  );
}

export default App;