import { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storeFormData } from '../../redux/slices/form/form';
import { throttle } from 'lodash';

import Form from '@rjsf/semantic-ui';
import { customizeValidator } from '@rjsf/validator-ajv8';
import { IoIosCloseCircle } from "react-icons/io";

import DraggableDiv from './utils/Dragdiv';
import ResponsiveDialog from './utils/ConfirmationDialog';
import SendDataToBackend from '../utils/SendDataToBackend';
import FetchTemplate from './utils/fetchTemplate';
import extractTemplateSchema from './utils/extractTemplateData';
import ConverterFunction from './utils/ConverterFunction';
import GenerateSchema from "./utils/GenerateSchema";
import { getSchema } from './utils/schemas';

const ElementModel = ({ publish, visible, elementType, onClose, text, objectID, Graph, }) => {
  const [templateData, setTemplateData] = useState(null);
  const [inputSchema, setInputSchema] = useState({});
  const [uiSchema, setUiSchema] = useState({});
  const [schema, setSchema] = useState(null);

  const dispatch = useDispatch();
  const formData = useSelector(state => state.form.data.find(item => item.objectID === objectID) || {});
  
  const templateURL = {
    AOP: 'https://np.knowledgepixels.com/RACcFp3fLHwh6_vH4aNZa2Z3WBiHb389a1ERO1Hc5uRS0.jsonld',
    KeyEvent: 'https://np.knowledgepixels.com/RALUzHtS1E_Mba_zwMhhENjZgd23vM0IivF-tUBC633dI.jsonld'
  };

  useEffect(() => {
    if (elementType === 'AOP') {
      FetchTemplate(templateURL.AOP).then(data => setTemplateData(data[1]["@graph"]));
    } else {
      FetchTemplate(templateURL.KeyEvent).then(data => setTemplateData(data[1]["@graph"]));
    }
  }, [elementType]);

  useEffect(() => {
    if (templateData) {
      const { outputSchema, uiSchema } = GenerateSchema(extractTemplateSchema(templateData), text);
      setInputSchema(outputSchema);
      setUiSchema(uiSchema);
    }
  }, [templateData, text]);
    
  useEffect(() => {
    setSchema(getSchema(elementType, inputSchema));
    if(!publish){
      setUiSchema(prevUiSchema => ({
        ...prevUiSchema,
        ID: {
          "ui:widget": "text",
          "ui:options": {
            "readonly": true
          }
        },
      }));
    }
    else{
      const updatedFormData = ({
        ...formData,
        Graph: Graph,
      });
      dispatch(storeFormData(JSON.stringify(updatedFormData, null, 2)));
    }
    setUiSchema(prevUiSchema => ({
      ...prevUiSchema,
      Graph: {
        "ui:widget": "hidden",
        "ui:options": {
          "readonly": true
        }
      },
    }));
  }, [elementType, inputSchema, text, Graph, publish]);
        
  const validator = customizeValidator();
    
  const validateForm = () => {
    const result = validator.validateFormData(formData, schema);
    return result.errors.length === 0;  
  };
    
  const handleSubmit = () => {
    SendDataToBackend(ConverterFunction([formData]));
    onClose();
  };

  const throttledOnChange = useCallback(throttle(event => {
    event.formData.objectID = objectID;
    dispatch(storeFormData(JSON.stringify(event.formData, null, 2)));
  }, 300), [dispatch, objectID]);
  
  if (!visible) return null;

  return (
    <>
      <DraggableDiv>
        {schema && (
          <div style={{
            position: 'relative',
            height: '100%',
            width: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
            padding: '10px',
            margin: '10px',
            background: 'white',
          }}>
            <div style={{ position: 'absolute', top: '0', right: '0', padding: '10px', zIndex: 100000 }}>
              <IoIosCloseCircle onClick={onClose} />
            </div>

            <Form
              schema={schema}
              onSubmit={handleSubmit}
              validator={validator}
              uiSchema={uiSchema}
              formData={formData}
              onChange={throttledOnChange}
            >
              <div style={{ textAlign: 'center' }}>
                <ResponsiveDialog onConfirm={handleSubmit} validateForm={validateForm} />
              </div>
            </Form>
          </div>
        )}
      </DraggableDiv>
    </>
  );
};

export default ElementModel;
