export async function SendDataToBackend(dataToSend) {
  const response = await fetch('http://127.0.0.1:8000/api/data', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(dataToSend),
    });

  const result = await response.json();
  console.log(result.message); // Should print "Data received successfully"
};
export default SendDataToBackend;